import './styles/CourseLables.css';

const CourseLables = () => {
  return (
    <div className='labels'>
      <h3>
        <i className='fas fa-circle' style={{ color: '#ea2027' }}></i>
        Computer Science
      </h3>
      <h3>
        <i className='fas fa-circle' style={{ color: '#78D237' }}></i>
        Scientific Computing
      </h3>
      <h3>
        <i className='fas fa-circle' style={{ color: '#FFD246' }}></i>
        Information Systems
      </h3>
      <h3>
        <i className='fas fa-circle' style={{ color: '#2D73F5' }}></i>
        Computer Systems
      </h3>
    </div>
  );
};

export default CourseLables;
