import { Link } from 'react-router-dom';

import './styles/CourseTitle.css';

const CourseTitle = (props) => {
  const { name, to = 'OS', deps, wrp } = props;
  return (
    <Link to={`/${to}`} style={{ textDecoration: 'none' }}>
      <div className={`course_title ` + (wrp && 'warp')}>
        {name}
        {deps.includes('cs') && <span className='cs'>&nbsp;</span>}
        {deps.includes('sc') && <span className='sc'>&nbsp;</span>}
        {deps.includes('is') && <span className='is'>&nbsp;</span>}
        {deps.includes('csys') && <span className='sys'>&nbsp;</span>}
      </div>
    </Link>
  );
};

export default CourseTitle;
