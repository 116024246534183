import './styles/Footer.css';

const Footer = () => {
  return (
    <footer>
      Developed and maintained by&nbsp;
      <a href='http://omar45.com' target='_blank' rel='noopener noreferrer'>
        Omar AbdulRahman
      </a>
    </footer>
  );
};

export default Footer;
