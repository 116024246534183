import { useEffect, useState } from 'react';

import CourseLables from './CourseLables';
import CourseTitle from './CourseTitle';

const Courses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetch(
      'https://raw.githubusercontent.com/omarr45/FCIS6thData/master/courses.json'
    )
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <CourseLables />
      {courses.map((course) => (
        <CourseTitle
          key={course._id}
          name={course.name}
          to={course.abb}
          deps={course.deps}
        />
      ))}
    </>
  );
};

export default Courses;
