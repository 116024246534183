import { useEffect, useState } from 'react';

import './styles/Announcements.css';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const isArabic = (text) => {
    const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    const result = pattern.test(text);
    return result;
  };

  useEffect(() => {
    document.title = 'FCIS 2023 6th | Announcements';
    fetch(
      'https://raw.githubusercontent.com/omarr45/FCIS6thData/master/announcements.json'
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setAnnouncements(data);
      });
  }, []);

  return (
    <div className='content'>
      <h1 className='ann_title'>Announcements</h1>
      <div className='announcements'>
        {announcements.map((ann) => (
          <div className='announcement' key={ann.id}>
            <h3>
              {ann.person} - <bdi> {ann.date}</bdi>
            </h3>
            {isArabic(ann.text.substring(0, 5)) ? (
              <p dir='rtl'>{ann.text}</p>
            ) : (
              <p dir='ltr'>{ann.text}</p>
            )}
            {ann.links.length > 0 && (
              <div className='links'>
                {ann.links.map((link, index) => (
                  <a
                    key={link.id + '-' + index}
                    href={link.url}
                    target='_blank'
                    rel='noreferrer'>
                    {`Link ${index + 1}`}
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Announcements;
