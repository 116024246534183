import './components/styles/styles.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AdBlockDetectedWrapper } from 'adblock-detect-react';
import Announcements from './components/Announcements';
import CoursePage from './components/CoursePage';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import NoMatch from './components/NoMatch';
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-EKWFGLGKVR';
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    document.title = 'FCIS2023 | 6th Semester';
    fetch(
      'https://raw.githubusercontent.com/omarr45/FCIS6thData/master/courses.json'
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setCourses(data);
      });
  }, []);

  return (
    <>
      <Router>
        <Navbar />
        <AdBlockDetectedWrapper>
          <div className='detected'>
            Please consider disabling Ad-Blocker to help us maintain this
            project
          </div>
        </AdBlockDetectedWrapper>

        <Routes>
          <Route path='/' exact element={<Home />} />
          {courses.map((course) => (
            <Route
              key={course._id}
              path={`/${course.abb}`}
              element={<CoursePage course={course} />}
              title={course.name}
            />
          ))}
          <Route path='/announcements' element={<Announcements />} />
          <Route path='*' element={<NoMatch />} />
        </Routes>
        <Footer year='2022' />
      </Router>
    </>
  );
};

export default App;
