import './styles/CoursePage.css';

import { useEffect, useState } from 'react';

import ReactGA from 'react-ga4';
import WeekSeparator from './WeekSeparator';

const CoursePage = ({ course }) => {
  const { name, abb, doctorName = 'Dr. John Doe', doctorEmail } = course;
  const [isEmpty, setIsEmpty] = useState(false);
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    document.title = `${name} | FCIS 2023`;

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: `CoursePage - ${name}`,
    });

    fetch(
      `https://raw.githubusercontent.com/omarr45/FCIS6thData/master/${abb}.json`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.length === 0) setIsEmpty(true);
        setWeeks(data);
      })
      .catch(() => {
        console.error(
          "Error!\nCouldn't find data for this course,\nPlease contact the admin."
        );
        setIsEmpty(true);
      });
  }, [name, abb]);

  return (
    <div className='content'>
      <h1 className='title'>{name}</h1>
      <h3
        title='Click to copy email'
        className='doctor'
        onClick={() => {
          navigator.clipboard.writeText(doctorEmail);
          alert(`'${doctorEmail}' was copied to clipboard`);
        }}>
        {doctorName}
      </h3>
      {isEmpty && (
        <div className='empty'>
          <h2>No materials found</h2>
          <p>
            Please check back later or contact the course admin for more
            information.
          </p>
        </div>
      )}
      {weeks.map((week) => (
        <WeekSeparator
          key={week.title}
          title={week.title}
          items={week.elements}
        />
      ))}
    </div>
  );
};

export default CoursePage;
