import './styles/Material.css';

import { useEffect } from 'react';
import { useState } from 'react';

const Material = ({ title, link, icon }) => {
  // Icons:
  // 0 - Video (fas fa-play-circle)
  // 1 - File PDF
  // 2 - File Word (fas fa-file-alt)
  // 3 - File Powerpoint (fas fa-file-alt)
  // 4 - File Rar (fas fa-file-alt)
  // 5 - Code (fas fa-code)
  // 6 - Book (fas fa-book)
  // 7 - Teams
  // 8 - Stream

  // Material Entries:
  // title - String
  // link - String
  // icon - Number

  const handleChange = (event) => {
    const val = event.target.checked;
    localStorage.setItem(title + link, val);
    setChecked(!checked);
  };

  const [checked, setChecked] = useState(
    localStorage.getItem(title + link) === 'true' ? true : false
  );

  useEffect(() => {
    if (!localStorage.getItem(title + link)) {
      localStorage.setItem(title + link, 'false');
    }
  }, [title, link]);

  const renderIcon = (icon) => {
    switch (icon) {
      case 0:
        return <i className='fas fa-play-circle'></i>;
      case 1:
        return <i className='fas fa-file-pdf'></i>;
      case 2:
        return <i className='fas fa-file-word'></i>;
      case 3:
        return <i className='fas fa-file-powerpoint'></i>;
      case 4:
        return <i className='fas fa-file-archive'></i>;
      case 5:
        return <i className='fas fa-code'></i>;
      case 6:
        return <i className='fas fa-book'></i>;
      case 7:
        return <i className='fas fa-user-friends'></i>;
      case 8:
        return <i className='fas fa-play'></i>;
      case 9:
        return <i className='fas fa-user-graduate'></i>;
      case 10:
        return <i className='fab fa-google-drive'></i>;
      case 11:
        return <i className='fas fa-question-circle'></i>;
      default:
        return <i className='fas fa-play-circle'></i>;
    }
  };

  return (
    <div className='material'>
      {renderIcon(icon)}
      <a href={link} target='_blank' rel='noreferrer noopener'>
        {title}
      </a>
      <label className='check_container'>
        <input type='checkbox' checked={checked} onChange={handleChange} />
        <span className='checkmark'></span>
      </label>
    </div>
  );
};

export default Material;
