import Material from './Material';

import './styles/WeekSeparator.css';

const WeekSeparator = ({ title, items }) => {
  // const getLastTenLetters = (text) => {
  //   return text.substr(0,5) + text.substr(text.length - 10);
  // };

  // 0 - Video (fas fa-play-circle)
  // 1 - File PDF (fas fa-file-pdf)
  // 2 - File Word (fas fa-file-word)
  // 3 - File Powerpoint (fas fa-file-powerpoint)
  // 4 - File Rar (fas fa-file-archive)
  // 5 - Code (fas fa-code)
  // 6 - Book (fas fa-book)

  return (
    <div>
      <div className='week'>
        <h1>{title}</h1>
      </div>
      {items?.map((material) => (
        <Material
          key={material.title}
          icon={material.icon}
          title={material.title}
          link={material.link}
        />
      ))}
    </div>
  );
};

export default WeekSeparator;
